export class Calculation {
  constructor() {

  }

  static getEvaluatedExpression(expression, args, itemId, appId) {
    const gen = parser(expression, args, itemId, appId);
    let curr, res;

    return new Promise(function (resolve, reject) {
      step();
      function step() {
        curr = gen.next();
        if (!curr.done) {
          curr.value.then(function (val) {
            res = val;
            step();
          }).catch(err => {
            reject(err);
          })
        } else {
          if (typeof res != 'undefined') {
            resolve(res);
          } else {
            resolve(expression);
          }

        }
      }
    });
  }
}

function* parser(expression, args, itemId, appId) {
  let funCallRegexp = /[A-Z_]+\(.*?\)/, res, argsCopy = JSON.parse(JSON.stringify(args)), currArgs;
  while (res = funCallRegexp.exec(expression)) {
    yield new Promise(function (resolve, reject) {
      currArgs = argsCopy.shift();
      switch (res[0].match(/[A-Z_]+/)[0]) {

        case 'SUM':
          evalSum(currArgs, appId, itemId).then(function (evalSumRes) {
            expression = expression.slice(0, res.index) + evalSumRes + expression.slice(res.index + res[0].length);
            resolve(expression);
          });
          break;

        case 'FIELD':
          evalField({ app_id: appId, item_id: itemId, field_id: currArgs.field_id, interpretation_type: currArgs.interpretation_type }).then(function (evalFieldRes) {
            // its happen when expression have commas instead dots 
            expression = expression.slice(0, res.index) + Number(evalFieldRes.toString().replace(/,/g, '.')) + expression.slice(res.index + res[0].length);
            // expression = expression.slice(0, res.index) + Number(eval(evalFieldRes)) + expression.slice(res.index + res[0].length);
            resolve(expression);
          });
          break;

        case 'IF':
          evalIf({ app_id: appId, item_id: itemId, items: currArgs.items, defaultValue: currArgs.defaultValue, mode: currArgs.mode }).then(function (evalFieldRes) {
            try {
              // its happen when expression have commas instead dots 
              expression = expression.slice(0, res.index) + Number(evalFieldRes.toString().replace(/,/g, '.')) + expression.slice(res.index + res[0].length);
              // expression = expression.slice(0, res.index) + Number(eval(evalFieldRes)) + expression.slice(res.index + res[0].length);
              resolve(expression);
            } catch(err) {
              reject(err);
            }
          });
          break;

        case 'FIELD_STRING':
          currArgs.item_id = itemId;
          evalFieldString(currArgs).then(function (evalFieldRes) {
            expression = expression.slice(0, res.index) + '"' + evalFieldRes + '"' + expression.slice(res.index + res[0].length);
            resolve(expression);
          });
          break;

        case 'WORD':
          expression = expression.slice(0, res.index) + '\'' + currArgs.word + '\'' + expression.slice(res.index + res[0].length);
          resolve(expression);
          break;

        case 'ITEM_INDEX':
          currArgs.item_id = itemId;
          currArgs.app_id = appId;
          switch (currArgs.type) {
            case 'Index number of item':
              gudhub.on('gh_item_get', {
                app_id: currArgs.app_id,
                item_id: currArgs.item_id
              }, function itemPipe(event, item = { index_number: "" }) {
                gudhub.destroy('gh_item_get', { app_id: currArgs.app_id, item_id: currArgs.item_id }, itemPipe);
                expression = expression.slice(0, res.index) + '"' + String((new Array(currArgs.min_digits_count || 0)).fill(0).join('').slice(item.index_number.toString().length - currArgs.min_digits_count) + item.index_number) + '"' + expression.slice(res.index + res[0].length);
                resolve(expression);
              }).emit('gh_item_get', {}, { app_id: currArgs.app_id, item_id: currArgs.item_id });
              break;
            case 'Current year':
              expression = expression.slice(0, res.index) + '"' + ("0" + (new Date()).getFullYear()).slice(-2) + '"' + expression.slice(res.index + res[0].length);
              resolve(expression);
              break;
            case 'Current month':
              expression = expression.slice(0, res.index) + '"' + ("0" + ((new Date()).getMonth() + 1)).slice(-2) + '"' + expression.slice(res.index + res[0].length);
              resolve(expression);
              break;
            case 'Current day of month':
              expression = expression.slice(0, res.index) + '"' + ("0" + (new Date()).getDate()).slice(-2) + '"' + expression.slice(res.index + res[0].length);
              resolve(expression);
              break;
          }
          break;
        case 'ITEMSCOUNT':
          evalItemsCount(currArgs, itemId, appId).then(function (evalFieldRes) {
            expression = expression.slice(0, res.index) + Number(evalFieldRes) + expression.slice(res.index + res[0].length);
            resolve(expression);
          });
          break;
      }
    });
  }
}

function evalField(argObj) {
  return new Promise(async (resolve) => {

    let interpretationType = 'gh_interpreted_value_get';//if there no interpretation_type then we use interpreted value value by default

    if (argObj.interpretation_type) {
      interpretationType = argObj.interpretation_type;
      delete argObj.interpretation_type;
    }

    if (interpretationType == 'gh_interpreted_value_get') {

      let value = await gudhub.getInterpretationById(argObj.app_id, argObj.item_id, argObj.field_id, 'value');

      if(value == '') {

        const model = await gudhub.getField(argObj.app_id, argObj.field_id);

        if (!model || model == undefined) {
          resolve('error');
          return;
        }

      }

      // Sometimes we doesn't have a value and because of this calculator crashing
      // That's why if there is no value we set it to "0"
      if(value == undefined || value == null || value == '') {
        value = 0;
      }
      
      resolve(value);

    } else {
      let value = await gudhub.getFieldValue(argObj.app_id, argObj.item_id, argObj.field_id);
      if(value === null) {
        value = await gudhub.getInterpretationById(argObj.app_id, argObj.item_id, argObj.field_id, 'value');
      }
      if(value == undefined || value == null || value == '') {
        value = 0;
      }
      resolve(value);
    }
  });
}

function evalIf(argObj) {
  return new Promise(async (resolve) => {
    gudhub.on('gh_item_get', { app_id: argObj.app_id, item_id: argObj.item_id }, async function itemPipe(event, item) {
      gudhub.destroy('gh_item_get', { app_id: argObj.app_id, item_id: argObj.item_id }, itemPipe);
      for (let i = 0; i < argObj.items.length; i++) {
        const preparetedFilterList = await gudhub.util.prefilter(JSON.parse(JSON.stringify(argObj.items[i].filters_list)), { element_app_id: argObj.app_id, app_id: argObj.app_id, item_id: argObj.item_id })
        const items = gudhub.util.filter([item], preparetedFilterList)
        // if mode field
        if (+argObj.mode) {
          if (items.length > 0) {
            return resolve(argObj.items[i].value);
          }
          if (i == argObj.items.length - 1) {
            return resolve(argObj.defaultValue);
          }
        }
        // if mode field
        if (!+argObj.mode) {
          let modelAddress = {
            app_id: argObj.app_id,
            item_id: argObj.item_id,
            field_id: argObj.items[i].value
          };
          gudhub.getInterpretationById(argObj.app_id, argObj.item_id, argObj.items[i].value, 'value').then(value => {
            if(items.length > 0) {
              resolve(value);
            }
            if(i == argObj.items.length - 1) {
              resolve(argObj.defaultValue);
            }
          });
        }

      }
    }).emit('gh_item_get', {}, { app_id: argObj.app_id, item_id: argObj.item_id });

  })
}

async function evalSum(args, appId, itemId) {
    let sum = 0;

    const app = await gudhub.getApp(args.app_id);

    const items = app.items_list;

    const preparetedFilterList = await gudhub.util.prefilter(JSON.parse(JSON.stringify(args.sum_filters)), { element_app_id: args.app_id, app_id: appId, item_id: itemId })
    const itemsList = gudhub.util.filter(items, preparetedFilterList);
    args.itself_filter.value = appId + '.' + itemId;
    const filteredItems = itselfFilter(itemsList, args.itself_filter);

    let fieldModel;

    for(let k = 0; k < app.field_list.length; k++) {
      if(app.field_list[k].field_id == args.field_id) {
        fieldModel = app.field_list[k];
        break;
      }
    }

    if (!fieldModel || fieldModel == undefined) {
      return 'error';
    }

    for(let i = 0; i < filteredItems.length; i++) {
      let fieldValue;
      for(let j = 0; j < filteredItems[i].fields.length; j++) {
        if(filteredItems[i].fields[j].field_id == args.field_id) {
          fieldValue = Number(filteredItems[i].fields[j].field_value.toString().replace(/,/g, '.'));
          break;
        }
      }
      if(fieldValue) {
        switch(fieldModel.data_type) {
          case 'calculator':
            if(fieldModel.data_model.is_persistent_value) {
              sum += Number(fieldValue);
            } else {
              let value = await gudhub.getInterpretationById(args.app_id, filteredItems[i].item_id, args.field_id, 'value', fieldValue, fieldModel);
              sum += Number(value);
            }
            break;
          default:
            sum += Number(fieldValue);
        }
      } else {
        let value = await gudhub.getInterpretationById(args.app_id, filteredItems[i].item_id, args.field_id, 'value', fieldValue, fieldModel);
        sum += Number(value);
      }
    }

    return sum;
}

function evalFieldString(argObj) {
  return evalField(argObj).then(function (evalFieldResult) {
    if (!evalFieldResult) {
      return '';
    } else {
      switch (argObj.field_settings.display) {

        case 'full_value':
          return evalFieldResult;

        case 'first_characters':
          return evalFieldResult.slice(0, argObj.field_settings.count);

        case 'each_word_first_character':
          return evalFieldResult.split(' ').map(function (item) {
            return item.substring(0, 1).toUpperCase();
          }).join('');
      }
    }
  });
}

function evalItemsCount(argObj, currItemId, currAppId) {
  return new Promise(async (resolve) => {
    const items = await gudhub.getItems(argObj.app_id);
    const preparetedFilterList = await gudhub.util.prefilter(JSON.parse(JSON.stringify(argObj.sum_filters)), { element_app_id: argObj.app_id, app_id: currAppId, item_id: currItemId });
    const itemsList = gudhub.util.filter(items, preparetedFilterList);
    argObj.itself_filter.value = currAppId + '.' + currItemId;
    resolve((itselfFilter(itemsList, argObj.itself_filter)).length);
  });
}

function itselfFilter(items, filter) {
  if (!items || !items.length || !filter.active) return items || [];

  // temporary
  if (filter.field_to_filter) {
    filter.field_id = filter.field_to_filter;
  }
  // temporary

  return items.filter(function (item) {
    return item.fields.some(function (field) {
      return String(filter.field_id).split(',').some(function (eachFieldId) {
        return field.field_id == eachFieldId && field.field_value && field.field_value.split(',').indexOf(filter.value) !== -1;
      });
    });
  });
}

// export default new Calculation();